import { Box, styled } from '@mui/material';
import ImageWidthSkeletonCustom from 'components/@custom/ImageWidthSkeletonCustom/ImageWidthSkeletonCustom';
import { PATH_NAME } from 'constants/PathName';
import moment from 'moment';
import Image from 'next/image';
import Link from 'next/link';
import { useState } from 'react';
import { theme } from 'theme';
import { Locale } from 'types/i18n.types';
import { IPressReleases } from 'types/types';
import { DATE_FORMAT_dddd_MMMM_Do_YYYY } from 'utils/formatDate';
import ImageNotFound from '../../../../../public/images/category-not-found.jpg';

const BoxCustom = styled(Box)(({ theme }) => ({
  height: '100%',
  width: '100%',
}));

const WrapperTitleCustom = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginTop: theme.spacing(2),
}));

const TitleCustom = styled(Box)(({ theme }) => ({
  ...theme.typography.webParagraph02,
  color: theme.palette.global01[400],
  fontWeight: 600,
}));

const DescriptionCustom = styled(Link)(({ theme }) => ({
  marginTop: theme.spacing(1),
  color: theme.palette.neutral01[200],
  ...theme.typography.webHeading05,
  cursor: 'pointer',
  display: '-webkit-box',
  WebkitLineClamp: '2',
  WebkitBoxOrient: 'vertical',
  overflow: 'hidden',
  ':hover': {
    color: theme.palette.global01[400],
  },
}));

interface Props extends IPressReleases {
  isShowBrand?: boolean;
  locale: Locale;
  onClickPressReleaseCard?: () => void;
}

const LatestPressReleasesCardItem = (props: Props) => {
  const {
    isShowBrand,
    newsroom,
    content,
    coverImageUrl,
    publishDate,
    id,
    locale,
    onClickPressReleaseCard,
  } = props;

  const [isImageLoaded, setIsImageLoaded] = useState<boolean>(false);
  const [isCoverImageError, setIsCoverImageError] = useState<boolean>(false);
  const [isLogoImageError, setIsLogoImageError] = useState<boolean>(false);
  const [quality, setQuality] = useState<number>(10);

  return (
    <BoxCustom>
      <ImageWidthSkeletonCustom
        loaded={isImageLoaded}
        heightSkeleton={'100%'}
        sxSkeleton={{ borderRadius: '8px', position: 'absolute' }}
        children={
          <Link
            href={`${PATH_NAME.NEWSROOMS}/${newsroom?.id}${PATH_NAME.PRESS_RELEASES}/${id}`}
            className="image-hover"
            onClick={() => onClickPressReleaseCard?.()}
          >
            <Image
              src={coverImageUrl && !isCoverImageError ? coverImageUrl : ImageNotFound}
              quality={quality}
              alt="Cover Image"
              width={400}
              height={250}
              onLoad={() => {
                setIsImageLoaded(true);
                setTimeout(() => {
                  setQuality(90);
                }, 2000);
              }}
              onError={() => setIsCoverImageError(true)}
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'cover',
                borderRadius: 8,
                overflow: 'hidden',
                cursor: 'pointer',
                position: 'absolute',
              }}
            />
            <Box
              className="overlay"
              sx={{
                position: 'absolute',
                backgroundColor: theme.palette.neutral01[100],
                opacity: 0,
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                borderRadius: '8px',
                transition: '.3s ease-in-out',
              }}
            ></Box>
          </Link>
        }
      />

      <Box>
        <WrapperTitleCustom>
          {isShowBrand && (
            <>
              <Link
                href={`${PATH_NAME.NEWSROOMS}/${newsroom?.id}`}
                className="link-hover"
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '8px',
                  paddingRight: '4px',
                }}
              >
                <Image
                  src={newsroom?.logoUrl && !isLogoImageError ? newsroom?.logoUrl : ImageNotFound}
                  sizes="100%"
                  width={16}
                  height={16}
                  alt="Brand logo"
                  onError={() => setIsLogoImageError(true)}
                  style={{
                    width: '18px',
                    height: '18px',
                    objectFit: 'cover',
                    borderRadius: '4px',
                    border: '2px solid',
                    borderColor: theme.palette.neutral01[600],
                  }}
                />
                <Link href={`${PATH_NAME.NEWSROOMS}/${newsroom?.id}`}>
                  <TitleCustom>{newsroom?.subSourceName}</TitleCustom>
                </Link>
              </Link>
              <Box
                sx={{
                  width: '1px',
                  background: theme.palette.neutral01[500],
                  height: '16px',
                  marginX: '8px',
                }}
              ></Box>
            </>
          )}
          <TitleCustom>
            {publishDate && moment(publishDate).format(DATE_FORMAT_dddd_MMMM_Do_YYYY)}
          </TitleCustom>
        </WrapperTitleCustom>
        <DescriptionCustom
          href={`${PATH_NAME.NEWSROOMS}/${newsroom?.id}${PATH_NAME.PRESS_RELEASES}/${id}`}
          onClick={() => onClickPressReleaseCard?.()}
        >
          {content?.[locale]?.title}
        </DescriptionCustom>
      </Box>
    </BoxCustom>
  );
};

export default LatestPressReleasesCardItem;
